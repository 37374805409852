import $ from 'jquery';

$(document).ready(function () {
	// Close submenu when clicking outside elsewhere on the page
	$(document).click(function (ev) {
		$('.header-nav__item, .main-nav__item').removeClass('-is-active');
	});

	// Display submenu
	$('.has-submenu').click(function (e) {
		const $li = $(this).closest('li');
		$('.header-nav__item, .main-nav__item')
			.not($li)
			.removeClass('-is-active');

		$li.toggleClass('-is-active');
		e.preventDefault();
		e.stopPropagation();
	});

	$('.subNav__link').on('focus', function() { 
		// $('.subNav__link').parent
		$('.mainNav > .subNav > .subNav__link').css({ 'opacity': 1, 'maxHeight': '1000px', 'visibility': 'visible', 'transition': 'all 250ms ease-in-out' });
	 });

	function searchToggler() {
		$(this).toggleClass('-is-active');
		$('.searchBar').toggleClass('-is-active');
		if ($('.searchBar').hasClass('-is-active')) {
			$('.searchBar input').focus();
		}
	}
	function loginToggler() {
		$('.JS-loginToggle').toggleClass('-is-active');
		$('.loginLinks').toggleClass('-is-active');
	}

	$('.JS-searchToggle').on('click keyup', function(event) {
		if (event.keyCode === 13) {
			searchToggler();
		} else if (event.handleObj.type === 'click') {
			searchToggler();
		}
	});


	$('.JS-loginToggle').on('click keyup', function(event) {
		if (event.keyCode === 13) {

			loginToggler();
		} else if (event.handleObj.type === 'click') {
			loginToggler();
		}
	});

	var initialScroll;
	checkScrolled();

	window.addEventListener('scroll', function () {
		if (
			window.matchMedia('(min-width: 1024px)').matches &&
			(document.body.querySelector('.panel__heroBanner') ||
				document.body.querySelector('.panel__halfHeroBanner'))
		) {
			if (initialScroll) {
				initialScroll = false;
				disableScrolling();
				//window.scrollTo(0, 1);
				setTimeout(function () {
					enableScrolling();
				}, 1000);
			}

			checkScrolled();
		}
	});

	function checkScrolled() {
		if (window.scrollY === 0) {
		  $('body').removeClass('-scrolled');
		  initialScroll = true;
		} else {
		  $('body').addClass('-scrolled');
		  initialScroll = false;
		}
	  }

	function disableScrolling() {
		var x = window.scrollX;
		var y = window.scrollY;
		window.onscroll = function () {
			window.scrollTo(x, y);
		};
	}

	function enableScrolling() {
		window.onscroll = function () {};
	}
});
