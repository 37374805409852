import 'jquery-validation';
// Plugins
// import 'mmenu-js';
// import 'imagesloaded';
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import './modules/ajax-form';
// Modules
import './modules/analytics';
import './modules/animations';
import './modules/carousel';
import './modules/css';
import './modules/cssHelper';
import './modules/faqs';
import './modules/form';
import './modules/form-validation';
import './modules/header';
import './modules/image-gallery';
import './modules/masonry';
import './modules/mobile-menu';
import './modules/notification';
import './modules/svgIcon-sprite';
import './modules/videoBanner';
