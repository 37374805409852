/* OPEN CLOSE BOTTOM MOBILE CONTROLS */
document.addEventListener('DOMContentLoaded', () => {
	const body = document.querySelector('body');
	const menuTitle = document.querySelector('.mobile-app-bar__button-text');
	const hamburger = document.querySelector('.mobile-app-bar__hamburger');
	const mobileMenu = document.querySelector('.mobile-menu');
	const toggleMobileMenu = document.querySelector('.toggle-mobile-menu');

	toggleMobileMenu.addEventListener('click', (evnt) => {
		mobileMenu.classList.toggle('active');
		hamburger.classList.toggle('is-active');
		menuTitle.innerHTML = mobileMenu.classList.contains('active')
			? 'Close'
			: 'Menu';
		body.classList.toggle('mobile-menu-open');
	});
});

/* CREATE MOBILE MENU AND FUNCTION */
(function ($) {
	var screenWidth = 0;

	$(document).ready(function () {
		$(window)
			.on('resize', function () {
				screenWidth = $(this).width();
			})
			.trigger('resize');

		var $mobileNavigation = $('.mobile-menu');

		var $socialShares = $(
			`<div class="mobileShare">Share <div class="mobileShare__icons"><a href="https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}" target="_blank"><i class="fab fa-linkedin"></i></a> <a href="https://www.facebook.com/sharer/sharer.php" target="_blank"><i class="fab fa-facebook"></i></a></div></div>`,
		);
		var $mainMenu = $('.mainNav__list').clone();
		var $dynamicMobileMenu = $('<nav class="mobile-menu__nav"></div>');
		$mobileNavigation.append($dynamicMobileMenu);
		$mobileNavigation.append($socialShares);

		//remove BEM
		$mainMenu
			.find('*[class^="mainNav"]')
			.removeClass(function (index, css) {
				return (css.match(/(^|\s)mainNav\S+/g) || []).join(' ');
			})
			.addClass('mobile-menu__navItem');

		$mainMenu
			.find('*[class^="subNav"]')
			.removeClass(function (index, css) {
				return (css.match(/(^|\s)subNav\S+/g) || []).join(' ');
			})
			.addClass('mobile-menu__navItem');

		// construct dynamic menu area
		var menuID = 0;
		var parentMenuID = 0;
		var $backLink = $('<li class="backLink"><a>Back</a></li>');
		var $parentLink = null;

		$mainMenu.attr('data-menu-id', menuID);
		menuID += 1;

		$mainMenu.find('ul').each(function () {
			$(this).attr('data-menu-id', menuID);
			if (menuID > 0) {
				$parentLink = $(this)
					.parent('li')
					.addClass('has-menu')
					.clone()
					.removeClass('has-menu')
					.addClass('mobile-menu__heading');
				$(this).prepend($parentLink);

				parentMenuID = parseInt(
					$(this).parents('ul').attr('data-menu-id'),
				);

				var backToParentMenuName;
				if (parentMenuID == 0) {
					backToParentMenuName = 'Back to Main Menu';
				} else {
					backToParentMenuName =
						'Back to ' +
						$(this).parents('ul').first().siblings('a').text();
				}
				$backLink
					.find('a')
					.attr('data-go-to-menu', parentMenuID)
					.attr('role', 'button')
					.html(backToParentMenuName);
				$(this).prepend($backLink.clone());

				$(this)
					.siblings('a')
					.attr('data-go-to-menu', menuID)
					.attr('role', 'button');
			}
			menuID += 1;
		});

		var $currentMenu = $mainMenu.find('li.-is-selected');
		if ($currentMenu.length > 0) {
			$dynamicMobileMenu
				.empty()
				.append($currentMenu.last().parent('ul').clone());
		} else {
			$dynamicMobileMenu.html($mainMenu.prop('outerHTML'));
		}

		$dynamicMobileMenu.on('click', 'li.has-menu > a', function (e) {
			e.preventDefault();

			var goToMenuID = parseInt($(this).attr('data-go-to-menu'));
			var $subMenu;

			if (goToMenuID == 0) {
				$subMenu = $mainMenu.clone();
			} else {
				$subMenu = $mainMenu
					.find('ul[data-menu-id="' + goToMenuID + '"]')
					.first()
					.clone();
			}

			$dynamicMobileMenu.animate(
				{ left: -screenWidth },
				200,
				function () {
					$dynamicMobileMenu
						.hide()
						.animate({ left: 0 }, 5, function () {
							$dynamicMobileMenu.fadeIn(200).html($subMenu);
						});
				},
			);
		});

		function goBack(goToMenuID) {
			var $subMenu;

			if (goToMenuID == 0) {
				$subMenu = $mainMenu.clone();
			} else {
				$subMenu = $mainMenu
					.find('ul[data-menu-id="' + goToMenuID + '"]')
					.first()
					.clone();
			}

			$dynamicMobileMenu.fadeOut(200, function () {
				$dynamicMobileMenu
					.css({ left: -screenWidth })
					.show()
					.animate({ left: 0 }, 200)
					.html($subMenu);
			});
		}

		$dynamicMobileMenu.on('click', 'li.backLink > a', function (e) {
			e.preventDefault();
			goBack($(this).attr('data-go-to-menu'));
		});

		/* swipe menu to go back */
		let touchstartX = 0;
		let touchendX = 0;
		let touchstartY = 0;
		let touchendY = 0;

		const swiper = document.querySelector('.mobile-menu');

		function handleGesure() {
			if (touchendX < touchstartX) {
				//alert('swiped left!');
				//Currently no action on swipe left.
			}
			if (touchendX > touchstartX) {
				//alert('swiped right!');
				if (
					touchendX - touchstartX > 150 &&
					Math.abs(touchendY - touchstartY) <= 70
				) {
					goBack(
						swiper
							.querySelector('li.backLink > a')
							.getAttribute('data-go-to-menu'),
					);
				}
			}
		}

		swiper.addEventListener('touchstart', (e) => {
			touchstartX = e.changedTouches[0].screenX;
			touchstartY = e.changedTouches[0].screenY;
		});

		swiper.addEventListener('touchend', (e) => {
			touchendX = e.changedTouches[0].screenX;
			touchendY = e.changedTouches[0].screenY;
			handleGesure();
		});
	});
})(jQuery);
