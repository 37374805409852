import $ from 'jquery';

$(document).ready(function () {
	// const $subNavLink = $('.subNav__link');
	// const $tertNavLink = $('')

	$('.subNav__link.-hasChildren + .subNav').each(function () {
		const $childItems = $(this).children();
		$childItems.each(function () {
			if ($(this).hasClass('-showInMainMenu')) {
				$(this)
					.parent()
					.siblings('.-hasChildren')
					.addClass('-getsIcon');
			}
		});
		// if ($(this).children().hasClass('.-showInMainMenu')) {
		// 	console.log(this);
		// }
	});
	if ($('[data-hide-sharer]').length) {
		$('.shareIcons').css('display', 'none');
	}
});
