import $ from 'jquery';
$(function ($) {
	addBodyPadding();
	
	var closeIcon = $('.notificationBar__closeIcon');
	if ($('.notificationBar').hasClass('-reveal')) {
		closeIcon.addClass('fa-angle-down');
	} else {
		closeIcon.addClass('fa-angle-up');
	}
	// Hide notification bar
	$('.notificationBar__closeModule').on('click', function () {
		sessionStorage.setItem('showNotificationBar', 'true');
		$(this).parents('.notificationBar').toggleClass('-reveal');       
		if ($('.notificationBar').hasClass('-reveal')) {
			closeIcon.removeClass('fa-angle-up');
			closeIcon.addClass('fa-angle-down');
		} else {
			closeIcon.removeClass('fa-angle-down');
			closeIcon.addClass('fa-angle-up');
		}
		addBodyPadding();
	});
	if (window.sessionStorage.getItem('showNotificationBar') == null) {
		$('.notificationBar').addClass('-reveal');
		closeIcon.removeClass('fa-angle-up');
		closeIcon.addClass('fa-angle-down');
		addBodyPadding();
	}

	
	window.addEventListener('resize', function () {
		if(this.resizeTO) clearTimeout(this.resizeTO);
		this.resizeTO = setTimeout(function() {
			if ($('.notificationBar').hasClass('-reveal')) {
				addBodyPadding();
			}
		}, 500);
	});
	
	function addBodyPadding() {		
		// Check if notification bar is active/not and set its height accordingly
		if ($('.notificationBar').hasClass('-reveal')) {
			var notificationBarHeight = document.getElementsByClassName('notificationBar')[0].scrollHeight;
		} else {
			var notificationBarHeight = 0;
		}
		// On desktop the Plastyk logo appears to the right and is covered by the close/open notification button,
		// On mobile the Plastyk logo appears to the left side of the footer and hence is not covered.
		if ($('body').hasClass('-is-homepage') && window.matchMedia('(min-width: 1024px)').matches) {

			$('body').css('padding-bottom', (notificationBarHeight + 140) + 'px');
		} else {
			$('body').css('padding-bottom', notificationBarHeight + 'px');
		}
	}
});

