import jQuery from 'jquery';

jQuery(function ($) {
	$('.carousel').slick({
		infinite: true,
		slidesToShow: 4,
		prevArrow:
			'<button type="button" class="carousel__arrow carousel__arrow--previous"><i class="fal fa-angle-left"></i></button>',
		nextArrow:
			'<button type="button" class="carousel__arrow carousel__arrow--next"><i class="fal fa-angle-right"></i></button>',
		responsive: [
			{
				breakpoint: 1280,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	});
});
