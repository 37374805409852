// https://www.npmjs.com/package/slick-carousel

$(document).ready(function() {
	
	var allFaqs = $('.faq-accordion .faq');

	$('.faq-accordion .faq').click(function(){

		$('.faq-accordion .faq').not(this).each(function(){
			$(this).find('.answer').slideUp(300);
			$(this).removeClass('open');
		});

		if ($(this).hasClass('open')) {
			$(this).find('.answer').slideUp(300);
			$(this).removeClass('open');
		}
		else {
			$(this).find('.answer').slideDown(300);
			$(this).addClass('open');
		}

	});
});
